<template>
  <div class="policy-wrapper">
    <div class="policy-title">Политика конфиденциальности</div>

    <p>
      Согласие на сбор и обработку персональных данных и сообщения о правах согласно
      Закону Украины «О защите персональных данных» от 01.06.2010 № 2297-VI
    </p>
    <p>
      Клиент (далее - пользователь) обязуется предоставить проекту «BibleAwr» (далее -
      владелец) достоверную, точную и полную информацию о себе по вопросам, предлагаемым в
      регистрационной форме, и поддерживать эту информацию в актуальном состоянии по
      необходимости. Если пользователь предоставляет неверную информацию или у владельца
      есть серьезные основания полагать, что информация, предоставленная Клиентом,
      является неверной, неполной или неточной, Владелец имеет право приостановить или
      отменить регистрацию или полностью отказать пользователю в дальнейшем размещении
      заказ.
    </p>
    <p>
      Пользователь подтверждает, что, действуя по своей воле и в своих интересах, дает
      согласие на обработку своих персональных данных, предоставленных владельцем, с
      преимуществами для их идентификации.
    </p>
    <p>
      Персональные данные пользователя, предоставленные владельцем при его регистрации в
      соответствии с действующим законодательством о защите персональных данных, не
      подлежат передаче третьим лицам без письменного разрешения пользователя, за
      исключением случаев права, предусмотренных действующим законодательством.
      законодательства, либо на основании судебных решений страны пребывания пользователя.
    </p>
    <p><b> Владелец использует только личную информацию: </b></p>
    <p>
      для регистрации пользователя на сайте; <br />
      для выполнения своих обязательств перед пользователем и его идентификации; <br />
      для оценки и анализа сайта; <br />
      для определения возможности участия пользователя в рекламных акциях , принадлежащий
      собственнику.
    </p>
    <p>
      Владелец может отправить пользователю сообщение. Рекламно-информационный характер.
    </p>
    <p>
      Отметив пункт на странице регистрации этого веб-сайта «Я согласен на сбор и
      использование моих личных данных», пользователь подтверждает, что получил
      уведомление, и соглашается со следующим:
    </p>
    <p>
      При использовании Интернет-сайта владелец собирает и обрабатывает персональные
      данные пользователя, связанные с идентификацией, аутентификацией, авторизацией,
      восстановлением пароля, отправкой информационных материалов по подписке
      пользователя, ответами на запросы и письма пользователей, оставлением отзывов о
      собственнике. полученные услуги, для регистрации, получения рекламных и специальных
      предложений, информация об акциях, другая информация о деятельности владельца, среди
      которых могут быть следующие персональные данные:
    </p>
    <p>фамилия, имя, отчество, телефон, адрес электронной почты;</p>
    <p>
      Персональные данные, предоставляемые пользователем при заполнении регистрационных
      форм на сайте, а также в процессе использования Сайта:
    </p>
    <p><b> Файлы cookie. </b></p>
    <p>
      Под обработкой персональных данных понимается сбор, регистрация, накопление,
      хранение, адаптация, уточнение (обновление, изменение), использование,
      распространение (в том числе передача Третьим лицам), защита, уничтожение и другие
      действия (операции) с персональными данными. .
    </p>
    <p>
      Пользователь обязан изменить свои личные данные. Как можно скорее предоставьте
      владельцу информацию.
    </p>
    <p>
      Владелец включает данные пользователя в базу личных данных с моментом, когда
      пользователь впервые предоставляет (отправляет) ваши личные данные держателя,
      заполнив регистрационную форму на веб-сайте владельца.
    </p>
    <p>
      Владелец использует обезличенные данные для таргетинга рекламы и / или
      информационных материалов по возрасту, полу и другим данным; для статистических
      исследований; другими способами.
    </p>
    <p>
      Владелец базы данных не передает личные данные третьим лицам. без предварительного
      согласия пользователя. Владелец базы данных не получает согласия субъекта
      персональных данных на передачу ему персональных данных в случаях, предусмотренных
      действующим законодательством Украины.
    </p>
    <p>
      При использовании пользователем веб-сайт может иметь коды сторонних ресурсов
      Интернета, в результате чего такие третьи лица получают данные, указанные в
      подпунктах 7.2.3. - 7.2.5 пункта 7.2. Это согласие. Такими Интернет-ресурсами
      третьих лиц являются:
    </p>
    <p>Системы сбора статистики посещений сайта (например, Google Analytics и др.);</p>
    <p><b> Другие ресурсы; </b></p>
    <p>
      Пользователь имеет все права на защиту своих персональных данных, предусмотренные
      действующим законодательством Украины, в частности Законом Украины «О защите
      персональных данных», а именно:
    </p>
    <p>
      знать о местонахождении базы данных, содержащей его персональные данные, ее
      назначении и имени, местонахождении ее владельца или руководителя;
    </p>
    <p>
      получать информацию об условиях предоставления доступа к Персональным данным, в
      частности, информацию о третьих лицах, которым передаются его персональные данные,
      содержащиеся в базе персональных данных;
    </p>
    <p>
      получать не позднее 30 календарных дней со дня получения запроса, за исключением
      случаев, предусмотренных законодательством, информацию о том, хранятся ли его
      персональные данные в соответствующей базе персональных данных, а также получать
      содержание его личных данных, которые хранятся.
    </p>
    <p>
      вносить мотивированные изменения в изменение или уничтожение своих персональных
      данных любым владельцем и администратором этих баз, если эти данные обрабатываются
      незаконно или ненадежно;
    </p>
    <p>
      для защиты ваших персональных данных от незаконной обработки и случайной потери,
      уничтожения, повреждения из-за преднамеренного сокрытия, непредоставления или
      несвоевременного предоставления, а также для защиты от предоставления информации,
      которая не является надежной или ненадежной, достоинства и бизнеса репутация
      личности;
    </p>
    <p>
      обращаться по вопросам защиты своих прав на персональные данные в органы
      государственной власти, местного самоуправления, чьи полномочия относятся к
      осуществлению защиты персональных данных;
    </p>
    <p>
      Применять средства правовой защиты в случае нарушения законодательства о защите
      персональных данных.
    </p>
    <p>
      о защите от автоматизированного решения, имеющего для него юридические последствия.
    </p>
    <p>
      Владелец принимает все предусмотренные законодательством меры по Защите персональных
      данных пользователя, в частности обработка данных осуществляется на оборудовании,
      которое находится в Охраняемых помещениях с ограниченным доступом.
    </p>
    <p>
      Введя свои персональные данные на странице регистрации и нажав «Зарегистрироваться»,
      пользователь также подтверждает, что уведомлен о включении информации о нем в базу
      персональных данных. С указанной выше целью о его праве, определенном Законом
      Российской Федерации. Украина «О защите персональных данных», а также о лицах с
      Transmit для выполнения вышеуказанной цели.
    </p>
  </div>
</template>
<style scoped>
.policy-wrapper {
  max-width: 1218px;
  padding: 20px 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.policy-title {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  padding: 40px;
}
.policy-wrapper p {
  padding: 10px;
  font-size: 20px;
  line-height: 30px;
}
.policy-wrapper b {
  font-weight: 600;
}
</style>
